/* eslint-disable max-len */
export default {
  tagline: 'IMAGINE. INITIATE. IMPLEMENT',
  content:
    'Hop on the bandwagon, and join one of India’s largest Student-run hackathons. HackNITR is back with version 5.0, which is now fully offline!',

  imgs: {
    hack: {
      src: 'https://res.cloudinary.com/dzxgf75bh/image/upload/v1702201459/HackNitr/Hero%20Section/kgjxmevu0bvj4yn7ojfy.gif',
      alt: 'Hack',
    },
    nitr: {
      src: 'https://res.cloudinary.com/dzxgf75bh/image/upload/v1702201272/HackNitr/Hero%20Section/anjnbvyn94vxvpf8acbc.png',
      alt: 'NITR',
    },
    five: {
      src: 'https://res.cloudinary.com/dzxgf75bh/image/upload/v1702201281/HackNitr/Hero%20Section/b1iuqxt1meh81y8bxwbd.png',
      alt: 'FIVE',
    },
  },
  links: {
    discord: {
      icon: 'https://res.cloudinary.com/db4zymomi/image/upload/hacknitr/Discord-Logo-Black_ratsjn.svg',
      link: 'https://discord.com/invite/yuNgCHknJz',
    },
    discordMobile: {
      icon: 'https://res.cloudinary.com/ratnakar5938/image/upload/v1667584745/hn/Discord__software_-Logo_Wordmark-Black-Logo.wine_rhsgsh.png',
      link: 'https://discord.com/invite/yQZ368XPrB',
    },
  },
};
