/* eslint-disable max-len */
export default {
  id: 'faq',
  title: 'DO YOU HAVE A QUESTION?',
  content: "New to hackathon? Don't worry we got you covered with all the basic information.",
  questions: [
    {
      id: 1,
      question: 'What are the dates for HackNITR 5.0?',
      answer:
        'HackNITR 5.0 will be held on 2nd and 3rd March, 2024. We will be waiting for you with a warm welcome.',
    },
    {
      id: 2,
      question: 'How do I pre-register for the hackathon?',
      answer:
        "You can click the pre-register button on our website for pre-registrations. Fill in the form and let's start the journey.",
    },
    {
      id: 3,
      question: 'What is the participation fee?',
      answer:
        'Only a creative mind, enthusiasm, and interest are required to participate in the hackathon. In short, the hackathon is entirely free and without monetary charges.',
    },
    {
      id: 4,
      question: 'Who can participate?',
      answer: `Any student with a zeal to innovate and have some fun with creativity can apply. But the status of your application can be changed anytime by the organizing team. 
              The final decision is vested with the organizing team and the candidature can be changed based on the undertaking.`,
    },
    {
      id: 5,
      question: 'What all things do you need to with while attending the hackathon?',
      answer: `Although we will provide you with a lot of things, you should bring your laptop, chargers, extension cords, and any other hardware you might need. 
      Furthermore it is mandatory to bring a physical copy of your undertaking form signed by your college authority and the hacker ID assigned to you within 24 hrs before the event along with a valid govt. ID. 
      For further information please look at <a href="https://wakeful-echidna-360.notion.site/Things-to-carry-for-HackNITR-5-0-50baca07cae94fe8bdcfbefc99aea026" target="_blank">Things to carry for HackNITR 5.0 notion link</a>.\n
      
      Students faling to submit the MoU within the stipulated time will be disqualified from the event.`,
    },
    {
      id: 5,
      question: 'What could be the size of the team?',
      answer:
        "We're only looking for groups of 2 to 4 heroes this time. No solo adventurers allowed. Gather your buddies and get ready for an epic adventure together!",
    },
    {
      id: 6,
      question: 'Will my travel expenses be reimbursed?',
      answer:
        'Since HackNITR 5.0 is a student-run hackathon, we will not be able to cover your travel expenses.',
    },
    {
      id: 7,
      question: 'Can I participate online?',
      answer:
        'No, since HackNITR 5.0 is a completely offline hackathon, online participation is not encouraged.',
    },
    {
      id: 8,
      question: 'Will food be provided at the hackathon?',
      answer:
        'Of course!! Meals, water, snacks and most importantly- Coffee, will be provided FREE OF COST.',
    },
    {
      id: 9,
      question: 'Why should I participate in HackNITR?',
      answer:
        'You will always get an excellent opportunity to network with like-minded people, learn many things, get cool vibes and also, free goodies!! Also, if you emerge as a winner, then you can have a chance to win a cash prize along with goodies and merchandise.',
    },
    {
      id: 10,
      question: 'How will the teams be judged?',
      answer:
        'The teams will be judged based on skills of viability and technical complexity, presentation skills, and, most importantly, creativity.',
    },
    {
      id: 11,
      question: 'What is the Code of Conduct?',
      answer:
        'To ensure a positive experience among all the participants, we follow the <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf" target="_blank">MLH Code of Conduct</a>. It is advised that you go through it once :)',
    },
  ],
};
